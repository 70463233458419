/*@ngInject*/
function PipelinesController($log, $mdDialog) {
  const a = "\u03B1";
  const chi = "\u03C7";

  this.availablePipes = [
    {
      key: "hwe",
      name: "Hardy Weinberg",
      description: `Excludes sites out of HWE test (${chi}2 approx.)`,
      statName: a,
      nominalThreshold: 0.05,
      defaults: {
        critValue: 0.05,
        numSamples: 0
      }
    },
    {
      key: "binomMaf",
      name: "Allele Frequency",
      description: `
      Drop sites different thatn chose population allele frequency estimates
      <br/>
      at the bonferroni-corrected 2-sided alpha.
      <br/>
      Checks gnomad.genomes.af & gnomad.exomes.af by default
      `,
      statName: a,
      nominalThreshold: 0.025,
      defaults: {
        critValue: 0.025,
        estimates: [],
        privateMaf: 1e-4,
        snpOnly: true,
        numSamples: 0
      }
    }
  ];

  this.pipes = [];
  this.pipesInfo = {};
  this.bonferroni = 1;
  this.configs = {};

  const _clearPipe = () => {
    this.pipes = [];
    this.pipesInfo = {};
    this.bonferroni = 1;
    this.configs = {};
  };

  this.$onChanges = cObj => {
    if (cObj.job) {
      _clearPipe();

      if (cObj.job.currentValue) {
        const job = cObj.job.currentValue;

        this.configs =
          (job.search.pipelines && job.search.pipelines.config) || {};

          // Find gnomad.genomes.af or gnomad.exomes.af in the list of job.search.fieldNames
          // using case insensitive search, but return the case-sensitive values

          const gnomadGenomesAf = job.search.fieldNames.find((fieldName) => {
            return fieldName.toLowerCase() === "gnomad.genomes.af";
          });

          const gnomadExomesAf = job.search.fieldNames.find((fieldName) => {
            return fieldName.toLowerCase() === "gnomad.exomes.af";
          });

          const binomMafPipeIndex = this.availablePipes.findIndex((pipe) => {
            return pipe.key === "binomMaf";
          });

          if (gnomadGenomesAf) {
            this.availablePipes[binomMafPipeIndex].defaults.estimates.push(gnomadGenomesAf);
          }

          if (gnomadExomesAf) {
            this.availablePipes[binomMafPipeIndex].defaults.estimates.push(gnomadExomesAf);
          }
      }
    }

    if (cObj.queryData) {
      if (cObj.queryData.currentValue) {
        this.bonferroni = cObj.queryData.currentValue.hits.total.value;
      }
    }
  };

  const _addPipe = (filterKey, defaultObj) => {
    let config;

    if (!this.configs[filterKey]) {
      const corrected = defaultObj.nominalThreshold / this.bonferroni;

      config = Object.assign({}, defaultObj.defaults, {
        critValue: corrected
      });
    } else {
      config = Object.assign({}, this.configs[filterKey]);
    }

    config.key = filterKey;
    config.numSamples = this.numSamples;

    this.pipes.push(config);

    this.pipesInfo[filterKey] = {
      config,
      order: this.pipes.length
    };

    this.onPipe({ pipeline: this.pipes });
  };

  const _removePipe = fKey => {
    if (this.pipesInfo[fKey] === undefined) {
      return;
    }

    const { order } = this.pipesInfo[fKey];

    this.pipes.splice(order - 1, 1);
    delete this.pipesInfo[fKey];

    Object.keys(this.pipesInfo).forEach(filterKey => {
      if (this.pipesInfo[filterKey].order > order) {
        this.pipesInfo[filterKey].order = this.pipesInfo[filterKey].order - 1;
      }
    });
  };

  this.togglePipe = (filterKey, defaultObj) => {
    if (this.pipesInfo[filterKey] !== undefined) {
      _removePipe(filterKey);
      return;
    }

    _addPipe(filterKey, defaultObj);
  };
}

angular
  .module("sq.jobs.results.search.pipelines.component", [])
  .component("sqSearchPipelines", {
    bindings: {
      job: "<",
      queryData: "<",
      onPipe: "&",
      numSamples: '<'
    }, // isolate scope
    templateUrl:
      "jobs/results/search/pipelines/jobs.results.search.pipelines.tpl.html",
    controller: PipelinesController
  });
